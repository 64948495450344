import { endpoint } from 'constants/endpoint';
import { deviceInfo, getCurrentSession } from 'utils/function';
import { Request, hideLoader } from 'services/api/network';

export const getOrganizationsList = async () => {
  try {
    const response = await Request.get(
      `${
        endpoint.getOrganizationsList
      }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getSingleOrganizationDetails = async (data) => {
  try {
    const response = await Request.get(
      `${
        endpoint.getOrganizationsList
      }/${data}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const organizationsCollaboratorsUpdate = async (
  orgId,
  collaboratorId,
  data
) => {
  try {
    const response = await Request.put(
      `${endpoint.organizationsCollaborators}/${orgId}/collaborators/${collaboratorId}`,
      data
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const updateOrganizationsNameFunc = async (organizationsId2, data) => {
  try {
    const response = await Request.put(
      `${endpoint.updateOrganizationsName + organizationsId2}`,
      data
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getUserByEmailId = async (data) => {
  try {
    let email = data?.emailAddress;
    const response = await Request.get(
      `${
        endpoint.getUserByEmail
      }/${email}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const inviteUserCollaborators = async (id, data) => {
  try {
    const response = await Request.post(
      `${endpoint.inviteUser}/${id}/collaborators`,
      data
    );
    if ([200, 201].includes(response?.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const deleteColloborator = async (orgId, colloboratorId) => {
  try {
    const response = await Request.delete(
      `${
        endpoint.deleteColloborator
      }/${orgId}/collaborators/${colloboratorId}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getFilterListData = async (id, webUri) => {
  try {
    const response = await Request.get(
      `${
        endpoint.getFilterList
      }/${id}/filters?_ANALYTICS=${deviceInfo()}&fromWebUI=${webUri}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const updateOrganizationBilling = async (id, data) => {
  try {
    const response = await Request.put(
      `${endpoint.updateOrganizationsBilling}/${id}`,
      data
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const deleteFiletr = async (orgId, id) => {
  try {
    const response = await Request.delete(
      `${
        endpoint.deleteFilter
      }/${orgId}/filters/${id}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const createNewFilter = async (orgId, data) => {
  try {
    const response = await Request.post(
      `${endpoint.createNewFilter}/${orgId}/filters`,
      data
    );
    if ([200, 201].includes(response?.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const updateFilters = async (orgId, filterId, data) => {
  try {
    const response = await Request.put(
      `${endpoint.updateFilter}/${orgId}/filters/${filterId}`,
      data
    );
    if (response?.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};
