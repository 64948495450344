export const MESSAGE = {
  success: {
    login: 'You successfully logged in.'
  },
  common: {
    success: 'Successfully.',
    wentWrong: 'Something went wrong.',
    appError: 'Application error',
    loginInApp: 'Please log in to use Konstru',
    serverError: 'Internal server error',
    unauthorized: 'Unauthorized access',
    forbidden: 'Your session has expired',
    notResponding: "Server isn't responding! Please contact Administrator.",
    password:
      'Your new password must be at least 8 characters long and should have atleast one uppercase letter, one lowercase letter and one digit.',
    updateModelError: `There was an issue updating the model permissions : Cannot read properties of undefined (reading 'length')`,
    invalidUnitType:
      'Invalid unit type. Please provide one of the valid units: cm, mm, m, ft, in.'
  },
  createAccount: {
    emailAllreadyExist: 'A user with this email already exists.',
    confirmEmail: 'A confirmation email has been sent to you.',
    accountCreateSuccess: 'Your account was successfully created!',
    singUpSuccessfully: 'Sign Up successfully',
    cardFourFormValidation: 'Please fill out the form',
    crateAcError:
      'Unfortunately, there was an issue creating your account. Could not sign up. Please refresh your browser and try again'
  },
  login: {
    loginSuccess: 'You succesfully logged in',
    emailNotExist: 'A user with this email address does not exist',
    emailError: 'Please provide a valid email address'
  },
  resetPassword: {
    notRegistered: 'This email address is not registered.',
    invalidToken:
      'Token is invalid or has expired. Request to reset your password again',
    mailSent: 'We just sent you an email to reset your password'
  },

  profile: {
    password:
      'Your new password must be at least 8 characters long and should have atleast one uppercase letter, one lowercase letter and one digit.',
    conformPassword: 'Passwords do not match',
    email: 'Please enter valid email',
    theme: 'Your preferences have been updated',
    changePassword: 'Your password has been updated',
    updateProfile: 'Your profile has been updated.'
  },

  organization: {
    organizationDetailsMissing: 'Organizations details are missing.',
    inviteUserError:
      'User does not exist We need a little more info to send an invitation on your behalf',
    permissionSuccess:
      'The permissions for this organization have been updated',
    filterUpdateSuccess: 'The filter was successfully updated',
    updateOrganizationSuccess: 'The Organization was successfully updated',
    fromError: 'From feild is required',
    toError: 'To feild is required',
    roleSuccessfullyUpdated:
      'The permissions for this organization have been updated',
    paymentMethodAddedSucess:
      'Your method of payment has been added or verify from Konstru.',
    removePaymentMethod:
      'Your method of payment has been removed from Konstru.',
    createFilter: 'New Filter Created Successfully',
    removeBankCardError:
      "You only get three free models. Please remove or demote your paid models. Then you'll be able to remove your payment method.",
    billingAdminUpdatedSucess:
      'The Billing Administrator was successfully updated.'
  },

  createANewModal: {
    nameError: 'Name cannot be blank or contain only spaces'
  },

  modelDetails: {
    update: 'Your updates were successfully saved.',
    modelRemoveSuccess: 'Model was successfully removed.',
    deleteFilter: 'Are you sure you want to delete this item.',
    targetIdMissing: 'TargetId is missing',
    targetDetailsMissing: 'TargetVersion or TargetElements is missing'
  },
  userDetails: {
    detailsMissing: 'User detail not found',
    emailMissing: 'User email not found'
  }
};
