// Local Storage
// Function to set an item in local storage
export function setLocalStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

// Function to get an item from local storage
export function getLocalStorageItem(key) {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

// Function to remove an item from local storage
export function removeLocalStorageItem(key) {
  localStorage.removeItem(key);
}

// Session Storage
// Function to set an item in session Storage
export function setSessionStorageItem(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

// Function to get an item from session Storage
export function getSessionStorageItem(key) {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

// Function to remove an item from session Storage
export function removeSessionStorageItem(key) {
  sessionStorage.removeItem(key);
}

export function getSessionOrCreate(key) {
  const existingSessionVal = sessionStorage.getItem(key);
  if (existingSessionVal) return existingSessionVal;
  const newVal = new Date().getTime();
  sessionStorage.setItem(key, newVal);
  return newVal;
}
