import React from 'react';
import './footer.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
  let currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container fluid>
        <Row>
          <Col className="mobile-text-center">
            <a
              href="https://konstru.com/"
              className="footer-link text-muted"
              target="_blank"
              rel="noreferrer"
            >
              konstru.com
            </a>
            <a
              href="https://konstru.gitbook.io/konstru-manual/"
              className="footer-link text-muted"
              target="_blank"
              rel="noreferrer"
            >
              Documentation
            </a>
            <Link to="/bugReport" className="footer-link text-muted me-0">
              Report a Bug
            </Link>
          </Col>

          <div className="col text-end mobile-text-center pe-117">
            <a
              href="https://konstru.com/privacy/"
              className="footer-link text-muted mobile-d-block"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
            <span className="mb-0 footer-link text-muted">
              Copyright &copy; {currentYear} Konstru Inc.
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
