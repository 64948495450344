import axios from 'axios';
import { getLocalStorageItem } from 'services/others/storageServices';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { loaderFalse, loaderTrue } from 'redux/reducer/loaderReducer';
import { removeUserData } from 'redux/reducer/userReducer';
import { customToast } from 'utils/function';
import { MESSAGE } from 'constants/messages';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DATA_API_URL = BASE_URL + '/data/';
export const IMAGES_API_URL = BASE_URL + '/images/';

export const TOKEN = getLocalStorageItem('konstru_token') || '';

// for hide Loader extra header flag
export const hideLoader = {
  headers: {
    'X-Show-Loader': 'false' // Show loader for this API call
  }
};

// This will behave like a middleware for all api calling.
export const Request = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: TOKEN,
    'access-control-allow-origin': '*'
  }
});

export const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let hideLoader;
    const resInterceptor = async (response) => {
      response.headers['Authorization'] =
        await getLocalStorageItem('konstru_token');
      hideLoader = false;
      if (response.headers['X-Show-Loader'] !== 'false') {
        dispatch(loaderTrue());
        hideLoader = true;
      }
      return response;
    };

    const responseInterceptor = (response) => {
      if (hideLoader) {
        dispatch(loaderFalse());
      }
      return response;
    };

    const errInterceptor = (error) => {
      dispatch(loaderFalse());
      // Check the error status code and handle accordingly
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 500:
            if (
              error.response.data ==
              `please specify type of 'ADMIN', 'WRITE' or 'READ'`
            ) {
              customToast('error', MESSAGE.common.updateModelError);
              return error;
            } else {
              customToast('error', MESSAGE.common.serverError);
              return error.response;
            }
          case 401:
            if (
              error.config.url === '/auth/login' ||
              error.config.url === '/auth/forgot'
            )
              return error;
            customToast('error', MESSAGE.common.unauthorized);
            return error.response;
          case 403:
            if (
              error.config.url === '/auth/login' ||
              error.config.url === '/auth/forgot'
            )
              return error;
            customToast('error', MESSAGE.common.forbidden);
            localStorage.clear();
            dispatch(removeUserData());
            setTimeout(() => (window.location = '/'), 1000);
            return error.response;
          default:
            customToast('error', MESSAGE.common.notResponding);
            return error.response;
        }
      }

      return Promise.reject(error);
    };

    const interceptor = Request.interceptors.request.use(
      resInterceptor,
      errInterceptor
    );
    const ResponseInterceptor = Request.interceptors.response.use(
      responseInterceptor,
      errInterceptor
    );
    return () => {
      Request.interceptors.request.eject(ResponseInterceptor);
      Request.interceptors.response.eject(interceptor);
    };
  }, []);

  return children;
};
