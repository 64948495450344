import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, Outlet, useNavigate } from 'react-router-dom';
import { loaderFalse, loaderTrue } from 'redux/reducer/loaderReducer';
import { RequireAuth, RequireLogout } from 'utils/function';

// Lazy load components
const LandingPage = lazy(() => import('pages/landingPage/landingPage'));
const Login = lazy(() => import('pages/login/login'));
const ForgotPassword = lazy(() =>
  import('pages/forgotPassword/forgotPassword')
);
const SignUp = lazy(() => import('pages/signUp/signUp'));
const Profile = lazy(() => import('pages/profile/profile'));
const Dashboard = lazy(() => import('pages/dashboard/dashboard'));
const ResetPassword = lazy(() => import('pages/resetPassword/resetPassword'));
const CardOne = lazy(() => import('pages/createAccount/cards/cardOne'));
const CardTwo = lazy(() => import('pages/createAccount/cards/cardTwo'));
const CardThree = lazy(() => import('pages/createAccount/cards/cardThree'));
const CardFour = lazy(() => import('pages/createAccount/cards/cardFour'));
const BugReport = lazy(() => import('pages/bugReport/bugReport'));
const Organization = lazy(() => import('pages/organization/organization'));
const Models = lazy(() => import('pages/modelsList/models'));
const DownloadPlugins = lazy(() => import('pages/getPlugins/downloadPlugins'));
const DownloadingPlugins = lazy(() =>
  import('pages/getPlugins/downloadingPlugins')
);
const ModelsDetail = lazy(() => import('pages/modelDetails/modelsDetail'));
const Admin = lazy(() => import('pages/admin/admin'));
const UsageAnalytics = lazy(() =>
  import('pages/usageAnalytics/usageAnalytics')
);
const Page404 = lazy(() => import('pages/page404/page404'));
// ... import other components as needed

import Loader from '../utils/customLayouts/mainLayouts/loader/loader.jsx';

const FallbackComponent = () => {
  return (
    <>
      <div>
        <Loader />
      </div>
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireLogout>
          <LandingPage />
        </RequireLogout>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/login',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireLogout>
          <Login />
        </RequireLogout>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/forgot',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireLogout>
          <ForgotPassword />
        </RequireLogout>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/signup',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireLogout>
          <SignUp />
        </RequireLogout>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/profile',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      {
        index: true,
        Component() {
          return <Profile />;
        }
      },
      {
        path: 'image',
        Component() {
          return <Profile />;
        }
      },
      {
        path: 'preferences',
        Component() {
          return <Profile />;
        }
      },
      {
        path: 'organizations',
        Component() {
          return <Profile />;
        }
      },
      {
        path: 'profile',
        Component() {
          return <Profile />;
        }
      }
    ],
    errorElement: <Page404 />
  },
  {
    path: '/admin',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      {
        index: true,
        Component() {
          return <Admin />;
        }
      },
      {
        path: 'profile',
        Component() {
          return <Admin />;
        }
      },
      {
        path: 'organizations',
        Component() {
          return <Admin />;
        }
      },
      {
        path: 'testingGroups',
        Component() {
          return <Admin />;
        }
      }
    ]
  },
  {
    path: '/bugReport',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <BugReport />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/reset/:token',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireLogout>
          <ResetPassword />
        </RequireLogout>
      </Suspense>
    )
  },
  {
    path: '/dashboard',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      </Suspense>
    ),
    // children: [{ index: true, element: <Dashboard /> }, { path: ":organizationsId/models", element: <Organization /> }],
    errorElement: <Page404 />
  },
  {
    path: '/verify',
    element: (
      <Suspense>
        <RequireLogout>
          <Outlet />
        </RequireLogout>
      </Suspense>
    ),
    children: [
      {
        index: true,
        Component() {
          const navigate = useNavigate();
          useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const param1Value = urlParams.get('resetToken') || '';
            const param2Value = urlParams.get('email') || '';

            if (param2Value === '' || param1Value === '') {
              return navigate('/verify/create-account');
            }

            navigate(
              `/verify/create-account?resetToken=${param1Value}&email=${param2Value}`
            );
          }, []);
          return;
        }
      },
      {
        path: 'create-account',
        Component() {
          return <CardOne />;
        }
      },
      {
        path: 'choose-plan',
        element: <CardTwo />
      },
      {
        path: 'download-plugins',
        element: <CardThree />
      },
      {
        path: 'survey',
        element: <CardFour />
      }
    ],
    errorElement: <Page404 />
  },
  {
    path: '/organizations',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: ':organizationsId/models', element: <Organization /> },
      { path: ':organizationsId/people', element: <Organization /> },
      { path: ':organizationsId/settings', element: <Organization /> },
      { path: ':organizationsId/settings/details', element: <Organization /> },
      { path: ':organizationsId/settings/billing', element: <Organization /> },
      { path: ':organizationsId/settings/filters', element: <Organization /> }
    ],
    errorElement: <Page404 />
  },
  {
    path: '/models',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Models />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/downloadPlugins',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <DownloadPlugins />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/downloadingPlugins',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <DownloadingPlugins />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '/model',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: ':modelId/visualize/elements', element: <ModelsDetail /> },
      { path: ':modelId/visualize/merge', element: <ModelsDetail /> },
      { path: ':modelId/history', element: <ModelsDetail /> },
      { path: ':modelId/reports', element: <ModelsDetail /> },
      { path: ':modelId/people', element: <ModelsDetail /> },
      { path: ':modelId/settings', element: <ModelsDetail /> },
      { path: ':modelId/settings/settings', element: <ModelsDetail /> },
      { path: ':modelId/settings/filters', element: <ModelsDetail /> },
      { path: ':modelId/settings/export', element: <ModelsDetail /> },
      { path: ':modelId/settings/deleteModel', element: <ModelsDetail /> }
    ],
    errorElement: <Page404 />
  },
  {
    path: '/report',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: ':modelId/visualize/elements', element: <ModelsDetail /> },
      { path: ':modelId/visualize/merge', element: <ModelsDetail /> },
      { path: ':modelId/history', element: <ModelsDetail /> },
      { path: ':modelId/reports', element: <ModelsDetail /> },
      { path: ':modelId/people', element: <ModelsDetail /> },
      { path: ':modelId/settings', element: <ModelsDetail /> },
      { path: ':modelId/settings/settings', element: <ModelsDetail /> },
      { path: ':modelId/settings/filters', element: <ModelsDetail /> },
      { path: ':modelId/settings/export', element: <ModelsDetail /> },
      { path: ':modelId/settings/deleteModel', element: <ModelsDetail /> }
    ],
    errorElement: <Page404 />
  },
  {
    path: '/usageAnalytics',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <UsageAnalytics />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  },
  {
    path: '*',
    element: (
      <Suspense
        fallback={
          <>
            <FallbackComponent />
          </>
        }
      >
        <RequireAuth>
          <LandingPage />
        </RequireAuth>
      </Suspense>
    ),
    errorElement: <Page404 />
  }
]);
