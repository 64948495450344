import { addOrganizationsDetails } from '../../redux/reducer/organizationsReducer';
import {
  observer,
  refreshModelsAndVersions
} from '../../redux/reducer/observerReducer';
import { addModelsDetails, cloneModelNew } from 'redux/reducer/modelReducer';
import {
  getLocalStorageItem,
  getSessionOrCreate
} from '../others/storageServices';
import { activeModelList } from './modelServices';
import { customToast } from '../../utils/function';
import { EVENTS } from '../../utils/json';
import { store } from 'redux/store';
import { loaderFalse } from 'redux/reducer/loaderReducer';
import { MESSAGE } from 'constants/messages';

const organizationsDetails =
  store.getState()?.organizations?.organizationsDetails || [];
const modelsInRedux = store.getState()?.models?.models || [];
//the purpose of this factory is to listen to socket events from the various services
export const eventFactory = (socket) => {
  let currentUser =
    getLocalStorageItem('currentUser') || store.getState()?.user.userData;

  socket.on('connect', () => {
    // perform a proper callback function here
  });

  socket.on('reconnect', () => {
    if (currentUser?._id) {
      socket.emit('login', currentUser._id);
    }
  });
  socket.on('disconnect', () => {});

  socket.on('fromElementService', (notification) => {
    if (notification.author._id !== currentUser?._id) {
      activeModelList(false, false, true).then(() => {
        store.dispatch(refreshModelsAndVersions(true));
        store.dispatch(loaderFalse());
      });
      notifyUsers(notification, currentUser);
    } else {
      dispatchNotification(notification, currentUser);
    }
  });

  socket.on('fromOrganizationService', (notification) => {
    if (notification.author._id !== currentUser?._id) {
      activeModelList(false, false, true).then(() => {
        updateOrgDetailsBySocketData(notification?.data).then(() => {
          store.dispatch(refreshModelsAndVersions(notification));
          store.dispatch(loaderFalse());
        });
      });
      notifyUsers(notification, currentUser);
    } else {
      if (notification?.eventName === 'ModelCloned') {
        const modelsFromRedux = store.getState()?.models?.models || [];
        let clonedModel = [notification?.data, ...modelsFromRedux];
        let duplicate = modelsFromRedux.filter(
          (a) => a._id === notification?.data?._id
        );
        if (duplicate?.length === 0) {
          store.dispatch(addModelsDetails(clonedModel));
          store.dispatch(cloneModelNew([notification?.data]));
        }
      }
      dispatchNotification(notification, currentUser);
    }

    socket.off('fromOrganizationService', () => {
      return store.dispatch(refreshModelsAndVersions(false));
    });
  });

  // gateway sends analytic requests for the user dashboard:
  socket.on('fromAnalyticService', (notification) => {
    if (notification.author._id === currentUser?._id) {
      if (notification.message === 'success') {
        if (notification.data.length === 0) return;
        store.dispatch(observer[notification.eventName](notification.data));
      }
    }
  });
};

function notifyUsers(notification, currentUser) {
  if (
    notification.author._id !== currentUser?._id ||
    notification?.showInWebUI
  ) {
    customToast('info', notification?.message);
  }
}

function dispatchNotification(notification, currentUser) {
  if (notification.showInWebUI && currentUser)
    notifyUsers(notification, currentUser);

  // For handling data inside Version Merged event if it is coming empty.
  if (
    notification.eventName === 'VersionMerged' &&
    Object.keys(notification.data).length === 0
  ) {
    notification.data = { versionMerged: true };
  }
  // if (
  //   notification.eventName === 'VersionDiff' &&
  //   notification?.konstruSessionId
  // ) {
  //   store.dispatch(observer.MinimalModelAtts(notification.data));
  // }

  if (EVENTS[notification.eventName]) {
    if (notification?.konstruSessionId) {
      //get current sessionid and only broadcast if they match
      if (
        getSessionOrCreate('currentSession').toString() ===
        notification.konstruSessionId.toString()
      ) {
        store.dispatch(observer[notification.eventName](notification.data));
      }
    } else {
      store.dispatch(observer[notification.eventName](notification.data));
    }
  }
  // setTimeout(() => {
  //   if (
  //     notification.eventName === 'VersionDiff' &&
  //     notification?.konstruSessionId
  //   ) {
  //     store.dispatch(observer.MinimalModelAtts(notification.data));
  //   }
  // }, 3000);
}

const updateOrgDetailsBySocketData = async (data) => {
  if (organizationsDetails?.length === 0) {
    return customToast('info', MESSAGE.organization.organizationDetailsMissing);
  }
  let prevOrgDetails = JSON.parse(JSON.stringify(organizationsDetails));

  const updatedOrgList = prevOrgDetails?.map((item) =>
    item?._id === data?._id
      ? (item = { ...item, collaborators: data?.collaborators })
      : item
  );

  return store.dispatch(addOrganizationsDetails(updatedOrgList));
};
