import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  'refresh-models-and-versions': false,
  'minimal-model': null,
  'show-base-model': false,
  'organization-collaborators-updated': false,
  'model-dxf-export': '',
  'diff-result-ready': null,
  'model-added': null,
  'additional-element-atts-ready': null,
  'version-diff': null,
  'version-merged': null,
  'routes-analytics': []
};
export const observerSlice = createSlice({
  name: 'observers',
  initialState,
  reducers: {
    refreshModelsAndVersions: (state, action) => {
      state['refresh-models-and-versions'] = action.payload;
    },
    MinimalModel: (state, action) => {
      state['minimal-model'] = action.payload;
    },
    VersionMerged: (state, action) => {
      state['version-merged'] = action.payload;
    },
    VersionDiff: (state, action) => {
      state['version-diff'] = action.payload;
    },
    showBaseModel: (state, action) => {
      state['show-base-model'] = action.payload;
    },
    OrganizationCollaboratorsUpdated: (state, action) => {
      state['organization-collaborators-updated'] = action.payload;
    },
    DXFExport: (state, action) => {
      state['model-dxf-export'] = action.payload;
    },
    clearRefreshModelsAndVersions: (state) => {
      state['refresh-models-and-versions'] = false;
    },
    clearMinimalModel: (state) => {
      state['minimal-model'] = null;
    },
    clearVersionDiff: (state) => {
      state['version-diff'] = null;
    },
    clearVersionMerged: (state) => {
      state['version-merged'] = null;
    },
    clearShowBaseModel: (state) => {
      state['show-base-model'] = false;
    },
    clearOrganizationCollaboratorsUpdated: (state) => {
      state['organization-collaborators-updated'] = false;
    },
    clearModelDxfExport: (state) => {
      state['model-dxf-export'] = false;
    },
    ModelCloned: (state) => {
      state['model-cloned'] = false;
    },
    // VersionDiff: (state) => {
    //   state['diff-result-ready'] = false;
    // },
    ModelAdded: (state, action) => {
      state['model-added'] = action.payload;
    },
    MinimalModelAtts: (state, action) => {
      state['additional-element-atts-ready'] = action.payload;
    },
    routesAnalytics: (state, action) => {
      state['routes-analytics'] = action.payload;
    }
    // VersionDiff: "diff-result-ready"
    //ModelCloned: 'model-cloned',
  }
});

export const {
  refreshModelsAndVersions,
  MinimalModel,
  VersionDiff,
  MinimalModelAtts,
  VersionMerged,
  showBaseModel,
  fromOrganizationService,
  clearRefreshModelsAndVersions,
  clearMinimalModel,
  clearVersionDiff,
  clearShowBaseModel,
  clearOrganizationCollaboratorsUpdated,
  clearModelDxfExport,
  clearVersionMerged,
  ModelCloned
} = observerSlice.actions;

export const observer = observerSlice.actions;

export default observerSlice.reducer;
