import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loader: false,
  additionalModelLoader: false,
  analyticsLoader: false,
  analyticsSoketCall: false
  // analyticsLoaderMessage: 'Sorting through 250000 Entries'
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loaderTrue: (state) => {
      state.loader = true;
    },
    loaderFalse: (state) => {
      state.loader = false;
    },
    additionalModelLoaderFalse: (state) => {
      state.additionalModelLoader = false;
    },
    additionalModelLoaderTrue: (state) => {
      state.additionalModelLoader = true;
    },
    analyticsLoaderFalse: (state) => {
      state.analyticsLoader = false;
    },
    analyticsLoaderTrue: (state) => {
      state.analyticsLoader = true;
    },
    analyticsLoaderSoketDataFalse: (state) => {
      state.analyticsSoketCall = false;
    },
    analyticsLoaderSoketDataTrue: (state) => {
      state.analyticsSoketCall = true;
    }
  }
});

export const {
  loaderTrue,
  loaderFalse,
  additionalModelLoaderFalse,
  additionalModelLoaderTrue,
  analyticsLoaderFalse,
  analyticsLoaderTrue,
  analyticsLoaderSoketDataFalse,
  analyticsLoaderSoketDataTrue
} = loaderSlice.actions;

export default loaderSlice.reducer;
