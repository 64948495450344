export const sampleJson = {
  fruit: 'Apple',
  size: 'Large',
  color: 'Red'
};

export const modelsData = [
  {
    _id: '640ee5a56404f60002ea628b',
    status: 'active',
    restricted: {
      user: '',
      isLocked: false,
      date: 1687872201720
    },
    organization: '640ee5a46404f60002ea6289',
    name: "Dhruv's Konstru Sample Model",
    description: '',
    permissions: [
      {
        type: 'ADMIN',
        _id: '640ee5a56404f60002ea628c',
        user: '640ee5a46404f60002ea6288'
      },
      {
        type: 'ADMIN',
        _id: '649b14199b5bff000224fe6c',
        user: '649961f6f0d4d400023e7da6'
      },
      {
        user: '5d5ff4412f2b5a000442e29d',
        type: 'ADMIN',
        _id: '64d2136684025dcaa0cdc985'
      },
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e8904cb3bfcb535562921c'
      }
    ],
    updatedBy: '640ee5a46404f60002ea6288',
    dateCreated: '2022-11-29T09:35:07.463Z',
    clonedFrom: 'aa733bd7c5dfbc0004ec3a13/31cfd1bf-cea7-eb76-2087-e31764cdca6c',
    dateUpdated: '2023-08-25T11:28:12.242Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      },
      transformations: []
    },
    isValid: false,
    reports: [
      {
        _id: '64997fb0f0d4d400023e7dc8',
        name: "06/26/2023 - Dhruv's Konstru Sample Model Status Update",
        model: '640ee5a56404f60002ea628b',
        currentVersionID: '31cfd1bf-cea7-eb76-2087-e31764cdca6c',
        comparisonVersionID: 'ce73753e-7fb6-64de-3463-3e80316436ae',
        comparisonSettings: {
          X: 2,
          Y: 2,
          Z: 2,
          ID: true,
          Type: true,
          Attributes: true,
          Material: true,
          Profile: true,
          Geometry: true,
          Name: true,
          ShowEqual: true,
          ShowUpdated: true,
          ShowAdded: true,
          ShowDeleted: true,
          IgnoreDirection: true,
          _id: '64997fb0f0d4d400023e7dc9'
        },
        createdBy: '640ee5a46404f60002ea6288',
        permissions: [
          {
            type: 'ADMIN',
            _id: '64997fb0f0d4d400023e7dca',
            user: '640ee5a46404f60002ea6288'
          }
        ],
        dateCreated: '2023-06-26T12:08:16.193Z',
        dateUpdated: '2023-06-26T12:08:16.195Z',
        __v: 0
      }
    ]
  },
  {
    _id: '640ee67d6404f60002ea6292',
    status: 'active',
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    name: 'test',
    description: 'test',
    organization: '640ee5a46404f60002ea6289',
    updatedBy: '640ee5a46404f60002ea6288',
    permissions: [
      {
        type: 'ADMIN',
        _id: '640ee67d6404f60002ea6293',
        user: '640ee5a46404f60002ea6288'
      },
      {
        type: 'ADMIN',
        _id: '649b14199b5bff000224fe6d',
        user: '649961f6f0d4d400023e7da6'
      },
      {
        user: '5d5ff4412f2b5a000442e29d',
        type: 'ADMIN',
        _id: '64d2136684025dcaa0cdc986'
      },
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e8904cb3bfcb535562921d'
      }
    ],
    dateCreated: '2023-03-13T09:01:49.053Z',
    dateUpdated: '2023-08-25T11:28:12.242Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    isValid: false,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64145b276f76da0002aa48df',
    status: 'active',
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    name: 'test',
    description: 'TEST 17',
    organization: '640ee5a46404f60002ea6289',
    updatedBy: '640ee5a46404f60002ea6288',
    permissions: [
      {
        type: 'ADMIN',
        _id: '64145b276f76da0002aa48e0',
        user: '640ee5a46404f60002ea6288'
      },
      {
        type: 'ADMIN',
        _id: '649b14199b5bff000224fe6e',
        user: '649961f6f0d4d400023e7da6'
      },
      {
        user: '5d5ff4412f2b5a000442e29d',
        type: 'ADMIN',
        _id: '64d2136684025dcaa0cdc987'
      },
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e8904cb3bfcb535562921e'
      }
    ],
    dateCreated: '2023-03-17T12:20:55.904Z',
    dateUpdated: '2023-08-25T11:28:12.242Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    isValid: false,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64d272f084025dcaa0cf49fd',
    name: 'Konstru_Model_SimpleTest - InheritX',
    description: '',
    permissions: [
      {
        user: '5d5ff4412f2b5a000442e29d',
        type: 'ADMIN',
        _id: '61a0cdaee5702f000461235c'
      },
      {
        user: '64af87a438e9f458e3030303',
        type: 'ADMIN',
        _id: '64d2733984025dcaa0cf4b82'
      },
      {
        user: '640ee5a46404f60002ea6288',
        type: 'ADMIN',
        _id: '64d35b39b521c9ae9b0df89a'
      }
    ],
    dateCreated: '2021-10-14T15:54:00.819Z',
    versionLastUpdated: '2023-08-08T16:53:04.793Z',
    organization: '5d5ff4422f2b5a000442e29e',
    updatedBy: '5d5ff4412f2b5a000442e29d',
    status: 'active',
    clonedFrom: '61a0cdaee5702f000461235b/c0b96e1d-9cf9-4366-b818-98bfc47457fc',
    billingStatus: {
      planType: 'enterprise'
    },
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    dateUpdated: '2023-08-09T09:24:09.129Z',
    tags: [],
    isValid: true,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64df5e98e7e50d280386c6fa',
    name: 'asdasd',
    description: '',
    permissions: [
      {
        user: '640ee5a46404f60002ea6288',
        type: 'ADMIN',
        _id: '64df5e98e7e50d280386c6fb'
      },
      {
        user: '5d5ff4412f2b5a000442e29d',
        type: 'ADMIN',
        _id: '64df5e98e7e50d280386c6fc'
      }
    ],
    organization: '5d5ff4422f2b5a000442e29e',
    updatedBy: '640ee5a46404f60002ea6288',
    status: 'active',
    billingStatus: {
      planType: 'enterprise'
    },
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    dateCreated: '2023-08-18T12:05:44.386Z',
    dateUpdated: '2023-08-18T12:05:44.389Z',
    tags: [],
    isValid: true,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64e33d9acb8b7ea3303da175',
    name: "Jeet's Konstru Sample Model",
    description: '',
    permissions: [
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e33d9acb8b7ea3303da176'
      },
      {
        user: '640ee5a46404f60002ea6288',
        type: 'ADMIN',
        _id: '64e88907b3bfcb5355628d88'
      }
    ],
    dateCreated: '2017-11-29T09:35:07.463Z',
    organization: '64e33d99cb8b7ea3303da169',
    updatedBy: '64e33d99cb8b7ea3303da168',
    status: 'active',
    clonedFrom: 'aa733bd7c5dfbc0004ec3a13/31cfd1bf-cea7-eb76-2087-e31764cdca6c',
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    dateUpdated: '2023-08-25T10:57:11.481Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    isValid: true,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64e609e42047c2aeb0841610',
    name: 'jt',
    description: 'test',
    permissions: [
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e609e42047c2aeb0841611'
      },
      {
        user: '640ee5a46404f60002ea6288',
        type: 'ADMIN',
        _id: '64e88907b3bfcb5355628d89'
      }
    ],
    organization: '64e33d99cb8b7ea3303da169',
    updatedBy: '64e33d99cb8b7ea3303da168',
    status: 'active',
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    dateCreated: '2023-08-23T13:30:12.179Z',
    dateUpdated: '2023-08-25T10:57:11.481Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    isValid: true,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  },
  {
    _id: '64e6e7f42047c2aeb08764dc',
    name: 'test',
    description: 'for next screen testing',
    permissions: [
      {
        user: '64e33d99cb8b7ea3303da168',
        type: 'ADMIN',
        _id: '64e6e7f42047c2aeb08764dd'
      },
      {
        user: '640ee5a46404f60002ea6288',
        type: 'ADMIN',
        _id: '64e88907b3bfcb5355628d8a'
      }
    ],
    organization: '64e33d99cb8b7ea3303da169',
    updatedBy: '64e33d99cb8b7ea3303da168',
    status: 'active',
    restricted: {
      user: '',
      date: '',
      isLocked: false
    },
    dateCreated: '2023-08-24T05:17:40.403Z',
    dateUpdated: '2023-08-25T10:57:11.481Z',
    tags: [],
    billingStatus: {
      planType: 'free'
    },
    isValid: true,
    settings: {
      platforms: {
        revit: {
          mapping: {
            Forces: {
              P: {
                Name: 'P',
                UT: 4
              },
              V2: {
                Name: 'V2',
                UT: 4
              },
              V3: {
                Name: 'V3',
                UT: 4
              },
              T: {
                Name: 'T',
                UT: 5
              },
              M2: {
                Name: 'M2',
                UT: 5
              },
              M3: {
                Name: 'M3',
                UT: 5
              }
            }
          }
        }
      },
      units: {
        lengthUnit: 'mm',
        forceUnit: 'N'
      }
    },
    reports: []
  }
];

export const imagesAndVersions = {
  versions: [
    {
      model: '64145b276f76da0002aa48df',
      versions: []
    },
    {
      model: '640ee67d6404f60002ea6292',
      versions: []
    },
    {
      model: '640ee5a56404f60002ea628b',
      versions: [
        {
          _id: '5a733d81fe302800038b5478',
          ID: '76650617-dc04-c327-7369-74d1f945ddc9',
          Time: '2023-03-07T08:58:14.417Z',
          Message: 'Updated column sections on ground level',
          Tool: 'Excel',
          Author: '5821da88e2164600036769eb',
          Path: 'aa733bd7c5dfbc0004ec3a13/8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Status: 'UPDATE',
          diff: {
            0: 1,
            1: 260,
            2: 0,
            3: 20
          },
          Changes: [
            {
              Status: 1,
              DBID: '5a733d81fe302800038b535f'
            },
            {
              Status: 1,
              DBID: '5a733d81fe302800038b5362'
            },
            {
              Status: 1,
              DBID: '5a733d81fe302800038b5363'
            }
          ]
        },
        {
          _id: '5a733bc882985200031cb352',
          ID: '8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Time: '2023-03-05T08:58:14.417Z',
          Message: 'First Upload',
          Tool: 'Revit',
          Author: '58a6e41eac4fac0004e77aaf',
          Path: 'aa58c064bfd5ab000447b662/bf74f0a7-4fe2-fb37-16d9-7fab06b868fa',
          Status: 'UPLOAD',
          diff: {
            0: 280,
            1: 0,
            2: 0,
            3: 0
          },
          Changes: [
            {
              Status: 0,
              DBID: '5a733bc882985200031cb23a'
            },
            {
              Status: 0,
              DBID: '5a733bc882985200031cb23b'
            },
            {
              Status: 0,
              DBID: '5a733bc882985200031cb23c'
            }
          ]
        },
        {
          _id: '5a733e47fe302800038b5732',
          ID: '31cfd1bf-cea7-eb76-2087-e31764cdca6c',
          Time: '2023-03-11T08:58:14.417Z',
          Message: 'Updated brace section sizes',
          Tool: 'ETABS',
          Author: '5821da88e2164600036769eb',
          Path: 'aa733bd7c5dfbc0004ec3a13/ce73753e-7fb6-64de-3463-3e80316436ae',
          Status: 'UPDATE',
          diff: {
            0: 0,
            1: 323,
            2: 0,
            3: 25
          },
          Changes: [
            {
              Status: 3,
              DBID: '5a733e47fe302800038b55d6'
            },
            {
              Status: 3,
              DBID: '5a733e47fe302800038b55d7'
            }
          ]
        },
        {
          _id: '5a733dbafe302800038b55d5',
          ID: 'ce73753e-7fb6-64de-3463-3e80316436ae',
          Time: '2023-03-09T08:58:14.417Z',
          Message: 'Added roof and extension',
          Tool: 'Revit',
          Author: '58a6e41eac4fac0004e77aaf',
          Path: 'aa733bd7c5dfbc0004ec3a13/8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Status: 'UPDATE',
          diff: {
            0: 67,
            1: 281,
            2: 0,
            3: 0
          },
          Changes: [
            {
              Status: 0,
              DBID: '5a733dbafe302800038b5479'
            }
          ]
        }
      ]
    },
    {
      model: '64e6e7f42047c2aeb08764dc',
      versions: []
    },
    {
      model: '64e609e42047c2aeb0841610',
      versions: []
    },
    {
      model: '64e33d9acb8b7ea3303da175',
      versions: [
        {
          _id: '64e33d9a15e812000271b8cd',
          ID: '8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Time: '2023-08-13T10:34:02.500Z',
          Message: 'First Upload',
          Tool: 'Revit',
          Author: '58a6e41eac4fac0004e77aaf',
          Path: 'aa58c064bfd5ab000447b662/bf74f0a7-4fe2-fb37-16d9-7fab06b868fa',
          Status: 'UPLOAD',
          diff: {
            0: 280,
            1: 0,
            2: 0,
            3: 0
          },
          Changes: [
            {
              Status: 0,
              DBID: '5a733dbafe302800038b5479'
            }
          ]
        },
        {
          _id: '64e33d9a15e812000271b9e6',
          ID: '31cfd1bf-cea7-eb76-2087-e31764cdca6c',
          Time: '2023-08-15T10:34:02.500Z',
          Message: 'Updated brace section sizes',
          Tool: 'ETABS',
          Author: '5821da88e2164600036769eb',
          Path: 'aa733bd7c5dfbc0004ec3a13/ce73753e-7fb6-64de-3463-3e80316436ae',
          Status: 'UPDATE',
          diff: {
            0: 0,
            1: 323,
            2: 0,
            3: 25
          },
          Changes: []
        },
        {
          _id: '64e33d9a15e812000271bb43',
          ID: 'ce73753e-7fb6-64de-3463-3e80316436ae',
          Time: '2023-08-17T10:34:02.500Z',
          Message: 'Added roof and extension',
          Tool: 'Revit',
          Author: '58a6e41eac4fac0004e77aaf',
          Path: 'aa733bd7c5dfbc0004ec3a13/8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Status: 'UPDATE',
          diff: {
            0: 67,
            1: 281,
            2: 0,
            3: 0
          },
          Changes: []
        },
        {
          _id: '64e33d9a15e812000271bca0',
          ID: '76650617-dc04-c327-7369-74d1f945ddc9',
          Time: '2023-08-19T10:34:02.500Z',
          Message: 'Updated column sections on ground level',
          Tool: 'Excel',
          Author: '5821da88e2164600036769eb',
          Path: 'aa733bd7c5dfbc0004ec3a13/8cef33ec-c7c8-702f-2fb9-189aa1834112',
          Status: 'UPDATE',
          diff: {
            0: 1,
            1: 260,
            2: 0,
            3: 20
          },
          Changes: []
        }
      ]
    },
    {
      model: '64df5e98e7e50d280386c6fa',
      versions: []
    },
    {
      model: '64d272f084025dcaa0cf49fd',
      versions: [
        {
          _id: '64d272f1af14d6000247bb9f',
          ID: 'cc32b651-320f-4524-8aab-9c6a52f2e5f2',
          Time: '2021-10-14T16:10:35.532Z',
          Author: '5890a2f5a66a9e0004a6f755',
          Message: 'First Upload',
          Tool: 'Revit',
          Path: '',
          Status: 'UPDATED',
          MAC: '74D83E55A951',
          diff: {
            0: 31,
            1: 0,
            2: 0,
            3: 0
          },
          Changes: []
        },
        {
          _id: '64d272f1af14d6000247bbbf',
          ID: 'c0b96e1d-9cf9-4366-b818-98bfc47457fc',
          Time: '2021-10-14T16:14:15.488Z',
          Author: '5890a2f5a66a9e0004a6f755',
          Message: 'Elements added, modified or deleted',
          Tool: 'Revit',
          Path: '',
          Status: 'UPDATED',
          MAC: '74D83E55A951',
          diff: {
            0: 0,
            1: 26,
            2: 0,
            3: 5
          },
          Changes: [
            {
              Status: 0,
              DBID: '5a733dbafe302800038b5479'
            }
          ]
        }
      ]
    }
  ],
  images: [
    {
      signed_request:
        'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png',
      url: 'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png'
    },
    {
      signed_request:
        'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png',
      url: 'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png'
    },
    {
      signed_request:
        'https://konstru-model-images.s3.amazonaws.com/model_640ee5a56404f60002ea628b.png?AWSAccessKeyId=AKIAJ3JGR7VLS7V2NJ7A&Expires=1693170634&Signature=%2BlxHlmFW8YXz69mxSgkqetFuWr8%3D',
      url: 'https://konstru-model-images.s3.amazonaws.com/model_640ee5a56404f60002ea628b.png'
    },
    {
      signed_request:
        'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png',
      url: 'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png'
    },
    {
      signed_request:
        'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png',
      url: 'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png'
    },
    {
      signed_request:
        'https://konstru-model-images.s3.amazonaws.com/model_64e33d9acb8b7ea3303da175.png?AWSAccessKeyId=AKIAJ3JGR7VLS7V2NJ7A&Expires=1693170634&Signature=XY59tdVXA3CqLOb38Y7wAqyV3fI%3D',
      url: 'https://konstru-model-images.s3.amazonaws.com/model_64e33d9acb8b7ea3303da175.png'
    },
    {
      signed_request:
        'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png',
      url: 'https://s3.amazonaws.com/konstru-model-images/big-black-and-white-default.png'
    },
    {
      signed_request:
        'https://konstru-model-images.s3.amazonaws.com/model_64d272f084025dcaa0cf49fd.png?AWSAccessKeyId=AKIAJ3JGR7VLS7V2NJ7A&Expires=1693170634&Signature=Y%2FkjH%2FUm%2BJTw%2B4zAIFsMVlx5kyI%3D',
      url: 'https://konstru-model-images.s3.amazonaws.com/model_64d272f084025dcaa0cf49fd.png'
    }
  ]
};

export const EVENTS = {
  ModelConnectivity: 'model-connectivity-ready',
  ModelRepaired: 'model-repaired-ready',
  ModelRepairCommit: 'model-repair-commit-ready',
  VersionDiff: 'diff-result-ready',
  MinimalModel: 'vis-result-ready',
  MinimalModelAtts: 'additional-element-atts-ready',
  VersionMerged: 'merge-result-ready',
  ModelError: 'model-general-error',
  ModelAdded: 'model-added',
  ModelCloned: 'model-cloned',
  DXFExport: 'model-dxf-export',
  ProgressUpdate:
    'progress-update' /* meant to be used for a progress bar - not yet implemented*/,
  routesAnalytics: 'routes-analytics'
};
