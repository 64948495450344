import { configureStore } from '@reduxjs/toolkit';
import counterReducer from 'redux/reducer/counterReducer';
import loaderReducer from 'redux/reducer/loaderReducer';
import userReducer from 'redux/reducer/userReducer';
import organizationsReducer from 'redux/reducer/organizationsReducer';
import modelReducer from 'redux/reducer/modelReducer';
import observerReducer from 'redux/reducer/observerReducer';
import viewStyleKeyReducer from './reducer/viewStyleKeyReducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    counter: counterReducer,
    loader: loaderReducer,
    organizations: organizationsReducer,
    models: modelReducer,
    observers: observerReducer,
    viewStyleKey: viewStyleKeyReducer
    // All Reducer can be add here...
  }
});
