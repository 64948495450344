import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'App.scss';
import { RouterProvider } from 'react-router-dom';
import { router } from 'navigation/routes.js';
import InitialLoadingScreen from 'utils/customLayouts/mainLayouts/initialLoadingScreen/initialLoadingScreen';
import 'assets/font-awesome-4.7.0/css/font-awesome.min.css';
import { getLocalStorageItem } from 'services/others/storageServices';
import io from 'socket.io-client';
import { GlobalContext } from 'constants/globalContext';
import { eventFactory } from 'services/api/event.factory';
import { useDispatch, useSelector } from 'react-redux';
import { addUserData } from 'redux/reducer/userReducer';

function App() {
  const [isInitialization, setIsInitialization] = useState(true);
  const socketURL = process.env.REACT_APP_GATEWAYSERVICE_URL;
  const currentUserFromLocalStorage = getLocalStorageItem('currentUser');
  const dispatch = useDispatch();
  const currentUserFromRedux = useSelector((state) => state?.user?.userData);

  const socketInstance = io(socketURL);
  const [socket, setSocket] = useState(null);

  function _joinUserRoom(userId) {
    if (socketInstance) {
      socketInstance.emit('login', userId);
    }
  }

  useEffect(() => {
    const loadingScreen = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsInitialization(false);
    };
    loadingScreen();
    if (currentUserFromLocalStorage) {
      _joinUserRoom(currentUserFromLocalStorage?._id);
      dispatch(addUserData(currentUserFromLocalStorage));
    }
    if (socketInstance) {
      setSocket(socketInstance);
    }

    // clean up function for socket
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;
    if (currentUserFromRedux?._id || currentUserFromLocalStorage) {
      eventFactory(socket);
    }
  }, [socket, currentUserFromRedux, currentUserFromLocalStorage]);

  return (
    <div className="main">
      {isInitialization ? (
        <InitialLoadingScreen />
      ) : (
        <>
          <GlobalContext.Provider
            value={{
              socket
            }}
          >
            <RouterProvider router={router} />
          </GlobalContext.Provider>
        </>
      )}
    </div>
  );
}

export default App;
