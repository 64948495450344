import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  konstruAdmins: [],
  allKonstruUsers: [],
  allKonstruOrgs: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    removeUserData: (state) => {
      state.userData = {};
    },
    konstruAdminData: (state, action) => {
      if (action?.payload?.length > 0) {
        state.konstruAdmins = action.payload;
      }
    },
    removeKonstruAdminData: (state) => {
      state.konstruAdmins = [];
    },
    allKonstruUsersData: (state, action) => {
      if (action?.payload?.length > 0) {
        state.allKonstruUsers = action.payload;
      }
    },
    removeAllKonstruUsersData: (state) => {
      state.allKonstruUsers = [];
    },
    allKonstruOrgsData: (state, action) => {
      if (action?.payload?.length > 0) {
        state.allKonstruOrgs = action.payload;
      }
    },
    removeAllKonstruOrgsData: (state) => {
      state.allKonstruOrgs = [];
    }
  }
});

export const {
  addUserData,
  removeUserData,
  konstruAdminData,
  removeKonstruAdminData,
  allKonstruUsersData,
  removeAllKonstruUsersData,
  allKonstruOrgsData,
  removeAllKonstruOrgsData
} = userSlice.actions;

export default userSlice.reducer;
