import { endpoint } from 'constants/endpoint';
import { customToast, deviceInfo, getCurrentSession } from 'utils/function';
import { Request, hideLoader } from 'services/api/network';
import { MESSAGE } from 'constants/messages';

export const activeModelList = async (getImages, getVersions, getReports) => {
  let URL = `?getImages=${getImages}&getVersions=${getVersions}&getReports=${getReports}&_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`;
  try {
    const response = await Request.get(
      `${endpoint.getActiveModels}${URL}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const modelsImagesAndVersion = async (modelIds) => {
  if (modelIds?.length > 0) {
    const url = `?modelIds=${modelIds}&_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`;
    try {
      const response = await Request.get(
        `${endpoint.getImagesAndVersionOfModels}${url}`,
        hideLoader
      );
      if (response?.status === 200) {
        return response;
      } else {
        return response?.response;
      }
    } catch (error) {
      if (error?.response?.data) {
        return error?.response?.data;
      }
    }
  } else {
    // Sometimes IDs are missing. Need to handle it properly in refactoring.
    return customToast('info', MESSAGE.common.wentWrong);
  }
};

export const modelVersionsImage = async (data) => {
  try {
    const response = await Request.put(
      `${endpoint.getModelsVersionImages}`,
      data,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const modelVersionGroups = async (modelId, versionId) => {
  try {
    const response = await Request.get(
      `/api/models/${modelId}/data/versions/${versionId}/groups?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getMinimal = async (modelId, versionId, keys) => {
  const keyStr = keys && keys.length ? '?keys=' + keys.join(',') + '&' : '?';
  try {
    const response = await Request.get(
      `/api/models/${modelId}/data/versions/${versionId}/minimal${keyStr}_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getMinimalAttributes = async (modelId, versionId, keys) => {
  const keyStr = keys && keys.length ? 'keys=' + keys.join(',') : '';
  try {
    const response = await Request.get(
      `/api/models/${modelId}/data/versions/${versionId}/minimal?specificAttributesOnly=true&${keyStr}&_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const onNewCreateAModel = async (data) => {
  try {
    const response = await Request.post(`${endpoint.createANewModel}`, data);
    if (response?.status === 201) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getSingleModel = async (modelId, getImage) => {
  try {
    const response = await Request.get(
      `/api/models/${modelId}?getImage=${getImage}&getReports=${true}&_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};
export const getModelAttributes = async (modelId) => {
  try {
    const response = await Request.get(
      `${
        endpoint.createANewModel
      }${modelId}/data/attributes?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if ([200, 201].includes(response?.status)) {
      return response;
    } else {
      return response?.response;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const modelCollaboratorsUpdate = async (
  modelId,
  collaboratorId,
  data
) => {
  try {
    const response = await Request.put(
      `${endpoint.modelColloborater}/${modelId}/collaborators/${collaboratorId}`,
      data
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const removeModelColloborator = async (modelId, collaboratorId) => {
  try {
    const response = await Request.delete(
      `${
        endpoint.modelColloborater
      }/${modelId}/collaborators/${collaboratorId}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const inviteModelUser = async (modelId, payload) => {
  try {
    const response = await Request.post(
      `${endpoint.modelColloborater}/${modelId}/collaborators`,
      payload
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const updateModel = async (modelId, data) => {
  try {
    const response = await Request.put(
      `${endpoint.modelColloborater}/${modelId}`,
      data
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getSingleOrganization = async (orgId) => {
  try {
    const response = await Request.get(
      `${
        endpoint.getSingleOrg
      }/${orgId}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const exportGeometry = async (id, clonedId) => {
  // https://konstru-amc-dev.herokuapp.com/api/models/64d272f084025dcaa0cf49fd/data/dxf/
  // ?ID=c0b96e1d-9cf9-4366-b818-98bfc47457fc&_ANALYTICS=browser:Chrome_116.0.0.0,device:undefined_undefined,os:Linux_x86_64&konstruSessionId=1698304370139
  try {
    const response = await Request.get(
      `${
        endpoint.modelColloborater
      }/${id}/data/dxf/?ID=${clonedId}&_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const deleteModel = async (id) => {
  try {
    const response = await Request.delete(
      `${
        endpoint.modelColloborater
      }/${id}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response.status === 200) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const clone = async (modelId, data) => {
  try {
    const response = await Request.post(
      `${endpoint.modelColloborater}/${modelId}/clone`,
      data
    );
    if ([200, 201].includes(response.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const createReport = async (modelId, data) => {
  try {
    const response = await Request.post(
      `${endpoint.modelColloborater}/${modelId}/reports`,
      data
    );
    if ([200, 201].includes(response.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const DeletReport = async (modelId, reportId) => {
  try {
    const response = await Request.delete(
      `${
        endpoint.modelColloborater
      }/${modelId}/reports/${reportId}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`
    );
    if ([200, 201].includes(response.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

// http://localhost:1337/api/models/6553cc6081f4f04e1289c826/reports/658430efad4daac83f13ab2f

export const updateReport = async (modelId, reportId, data) => {
  try {
    const response = await Request.put(
      `${endpoint.modelColloborater}/${modelId}/reports/${reportId}`,
      data
    );
    if ([200, 201].includes(response.status)) {
      return response;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const createScreenshotFromRaw = async (rawImage, versionId = null) => {
  try {
    const data = {
      data: {
        data: rawImage,
        modelId: window.location.href.split('/')[4],
        versionId: versionId,
        _ANALYTICS: deviceInfo(),
        konstruSessionId: getCurrentSession()
      },
      method: 'POST'
    };

    const response = await Request.post(endpoint.uploadModelScreenShot, data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};
