export const ENUMS = {
  konstruPlatformEnums: [
    'Tekla',
    'Grasshopper',
    'Revit',
    'SAP',
    'Etabs',
    'RAM',
    'Konstru',
    'Excel'
  ],
  konstruElementTypeEnums: [
    'Area',
    'Beam',
    'Brace',
    'Cable',
    'Column',
    'Component',
    'Floor',
    'Foundation',
    'Grid',
    'Level',
    'Opening',
    'Wall'
  ],
  konstruPropertyEnums: ['Geometry', 'Name', 'Profile', 'Material'],
  konstruAnalysisPlatformEnums: ['SAP', 'RAM', 'Etabs'],
  konstruAbstractElementTypeEnums: [
    'Group',
    'Node',
    'GeoElement',
    'Releases',
    'Restraint',
    'AreaLoad',
    'LinearLoad',
    'LoadCase',
    'LoadCombination',
    'LoadPattern',
    'PointLoad',
    'Link',
    'LinkProperty'
  ]
};

export const forcesDefinition = {
  P: { label: 'Axial Force', title: 'Axial Force', unit: 'N' },
  V2: { label: 'Shear 1-2', title: '"Shear Force 1-2 Plane', unit: 'N' },
  V3: { label: 'Shear 2-3', title: '"Shear Force 2-3 Plane', unit: 'N' },
  T: { label: 'Axial Torque', title: 'Axial Torque', unit: 'Nm' },
  M2: { label: 'Bending 1-3', title: 'Bending Moment 1-3 Plane', unit: 'Nm' },
  M3: { label: 'Bending 1-2', title: 'Bending Moment 1-2 Plane', unit: 'Nm' }
};

export const AppConstants = {
  dateFormat: 'MM/DD/YYYY',
  surveys: {
    mostImportantFeatures: [
      { id: 1, title: 'Translating models from one tool into the other' },
      { id: 2, title: 'Tracking changes' },
      { id: 3, title: 'Sharing my model with others' },
      { id: 4, title: 'Viewing models online' },
      { id: 5, title: 'Workflow automation such as model repair' },
      { id: 6, title: 'Other' }
    ],
    softwareUsed: [
      { id: 1, title: 'Revit' },
      { id: 2, title: 'ETABS' },
      { id: 3, title: 'SAP2000' },
      { id: 4, title: 'Grasshopper' },
      { id: 5, title: 'Tekla Structures' },
      { id: 6, title: 'RAM Structural System' },
      { id: 7, title: 'Excel' },
      { id: 8, title: 'Dynamo' }
    ],
    softwareWanted: [
      { id: 1, title: 'Rhino (without GH)' },
      { id: 2, title: 'Autocad (regular)' },
      { id: 3, title: 'Autocad Architecture' },
      { id: 4, title: 'Autocad Structural' },
      { id: 5, title: 'Sketchup' },
      { id: 6, title: 'Strand7' },
      { id: 7, title: 'Autodesk Robot' },
      { id: 8, title: 'Other' }
    ]
  },
  forcesDefinition: {
    P: { label: 'Axial Force', title: 'Axial Force', unit: 'N' },
    V2: { label: 'Shear 1-2', title: '"Shear Force 1-2 Plane', unit: 'N' },
    V3: { label: 'Shear 2-3', title: '"Shear Force 2-3 Plane', unit: 'N' },
    T: { label: 'Axial Torque', title: 'Axial Torque', unit: 'Nm' },
    M2: { label: 'Bending 1-3', title: 'Bending Moment 1-3 Plane', unit: 'Nm' },
    M3: { label: 'Bending 1-2', title: 'Bending Moment 1-2 Plane', unit: 'Nm' }
  },
  defaultViewerPreferences: {
    colorSchemes: {
      default: 'light',
      repair: 'dark'
    }
  },
  modelRoles: [
    { value: 'ADMIN', displayText: 'Admin: Can invite Collaborators' },
    { value: 'WRITE', displayText: 'Modeler: Can update Models' },
    { value: 'READ', displayText: 'Viewer: Can only view and download' },
    {
      value: 'LIMITEDVIEW',
      displayText: "Limited: Can only see Reports they've been assigned to"
    }
  ]
};

export const ViewerColorsConstants = {
  dark: {
    node: '#888',
    edge: '#bbb',
    background: '#222',
    status: {
      change: '#E05916'
    }
  },
  light: {
    node: '#999',
    edge: '#444',
    background: '#eee',
    status: {
      change: '#E05916'
    }
  },
  white: {
    node: '#999',
    edge: '#444',
    background: '#fff',
    status: {
      change: '#E05916'
    }
  }
};
