import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.scss';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'utils/customLayouts/mainLayouts/loader/loader';
import { AxiosInterceptor } from 'services/api/network';

// React Init
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <React.Fragment>
      <AxiosInterceptor>
        <ToastContainer />
        <Loader />
        <App />
      </AxiosInterceptor>
    </React.Fragment>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
