import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getLocalStorageItem,
  getSessionStorageItem,
  setSessionStorageItem
} from 'services/others/storageServices';
import { toast } from 'react-toastify';
import MenuNavbar from 'utils/customLayouts/mainLayouts/navbar/menuNavbar';
import Footer from 'utils/customLayouts/mainLayouts/footer/footer';
import { MESSAGE } from 'constants/messages';
import UAParser from 'ua-parser-js';
import moment from 'moment/moment';
import { getModelUnits } from 'services/spectacles/modelDetailService';

export function RequireAuth({ children }) {
  // protected route
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!getLocalStorageItem('konstru_token')) {
      if (location.pathname === '/bugReport') {
        // window.history.replaceState(null, null, "/");
        customToast('info', MESSAGE.common.loginInApp);
        return navigate('/login');
      }
      localStorage.clear();
      return navigate('/');
    }
  });

  return (
    <>
      <MenuNavbar />
      {getLocalStorageItem('konstru_token') && (
        <div className="main-view">{children}</div>
      )}
      <Footer />
    </>
  );
}

export function RequireLogout({ children }) {
  let location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (getLocalStorageItem('konstru_token')) {
      // This if-condition will handle the case where the user has created their account and didn't complete the stage three and four then it should not be redirected to dashboard
      if (
        location.pathname === '/verify/download-plugins' ||
        location.pathname === '/verify/survey'
      ) {
        if (getLocalStorageItem('signUpProcess')) {
          return;
        }
      }
      return navigate('/dashboard');
    }
    return;
  }, []);
  return (
    <>
      <main>
        <MenuNavbar />
        <div className="main-view">{children}</div>
        <div className="global-footer">
          <Footer />
        </div>
      </main>
    </>
  );
}

export function UnprotectedRoute({ children }) {
  return (
    <>
      <MenuNavbar />
      {children}
      <Footer />
    </>
  );
}

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const customToast = (type, message) => {
  switch (type) {
    case 'error':
      toast.error(message, {
        position: 'top-center',
        hideProgressBar: true,
        toastId: 'customToast'
      });
      break;
    case 'success':
      toast.success(message, {
        position: 'top-center',
        hideProgressBar: true,
        toastId: 'customToast'
      });
      break;
    case 'info':
      toast.info(message, {
        position: 'top-center',
        hideProgressBar: true,
        toastId: 'customToast'
      });
      break;
    default:
      break;
  }
};

export const deviceInfo = () => {
  // by default it takes ua string from current browser's window.navigator.userAgent
  const parser = new UAParser().getResult();

  //this is a public variable
  const analyticsString =
    'browser:' +
    parser.browser.name +
    '_' +
    parser.browser.version +
    ',device:' +
    parser.device.type +
    '_' +
    parser.device.model +
    ',os:' +
    parser.os.name +
    '_' +
    parser.os.version;

  return analyticsString;
};

export const getCurrentSession = () => {
  let existingSession = getSessionStorageItem('currentSession');
  if (existingSession) {
    return existingSession;
  }
  let currentTimeStamp = new Date().getTime();
  setSessionStorageItem('currentSession', currentTimeStamp);
  return currentTimeStamp;
};

export const timeDifference = (lastUpdateTime) => {
  const timeDifference = new Date().getTime() - new Date(lastUpdateTime);

  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = 60 * millisecondsPerSecond;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;
  //const millisecondsPerWeek = 7 * millisecondsPerDay;
  const millisecondsPerMonth = 30 * millisecondsPerDay;
  const millisecondsPerYear = 365 * millisecondsPerDay; // Approximation

  const years = Math.floor(timeDifference / millisecondsPerYear);
  const months = Math.floor(timeDifference / millisecondsPerMonth);
  const days = Math.floor(
    (timeDifference % millisecondsPerMonth) / millisecondsPerDay
  );
  //const weeks = Math.floor(timeDifference / millisecondsPerWeek);
  const hours = Math.floor(
    (timeDifference % millisecondsPerDay) / millisecondsPerHour
  );
  const minutes = Math.floor(
    (timeDifference % millisecondsPerHour) / millisecondsPerMinute
  );

  switch (true) {
    case years > 0:
      return `${years} ${years === 1 ? 'year' : 'years'}`;
    case months > 0:
      return `${months} ${months === 1 ? 'month' : 'months'}`;
    case days > 0:
      return `${days} ${days === 1 ? 'day' : 'days'}`;
    case hours > 0:
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    case minutes > 0:
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    default:
      return 'few seconds';
  }
};

export const getDate = (timestamp) => {
  const dateObject = new Date(timestamp);

  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const year = dateObject.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function customSort(a, b) {
  const thisOrder = ['Etabs', 'Excel', 'RAM', 'Revit', 'SAP', 'Tekla'];
  const indexA = Math.max(thisOrder.indexOf(a.to), thisOrder.indexOf(a.from));
  const indexB = Math.max(thisOrder.indexOf(b.to), thisOrder.indexOf(b.from));
  return indexA - indexB;
}

export function getPathlastVariable(string) {
  let stringVal = string?.split('/');
  let pathName = stringVal[stringVal.length - 1];
  return pathName;
}

export function formatDateForCSV(dateString) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
}

export const formattedDateFromNow = (date) => {
  const timeAgo = moment(date).fromNow();
  return timeAgo;
};

export const lengthUnitMultipliers = {
  mm: 1,
  cm: 1 / 10,
  m: 1 / 1000,
  ft: 1 / 304.8,
  in: 1 / 25.4
  // Add other unit multipliers as needed
};

export function mmToUnits(input, unit = 'mm', showUnits = false, roundDigits) {
  // Ensure that we are working with a number
  if (isNaN(input)) return input;

  if (unit === 'default') {
    unit = getModelUnits().length;
  }
  // Check if optional parameters are passed, if not, use the defaults
  unit = unit || 'mm';
  showUnits = showUnits || false;

  let finalNum = input * lengthUnitMultipliers[unit];

  if (!isNaN(roundDigits)) {
    finalNum =
      Math.round(finalNum * Math.pow(10, roundDigits)) /
      Math.pow(10, roundDigits);
  } else {
    // Handle the case where roundDigits is not a number
    // For example:
    // finalNum = finalNum; // Handle as needed
  }
  if (showUnits) finalNum += ' ' + unit;
  return finalNum;
}

export const divideNumbers = (numerator, denominator) => {
  if (denominator !== 0) {
    let res = numerator / denominator;
    return parseFloat(res.toFixed(1));
  } else {
    return 0;
  }
};

const conversionFactors = {
  ft: 304.8, // 1 foot = 304.8 mm
  in: 25.4, // 1 inch = 25.4 mm
  m: 1000, // 1 meter = 1000 mm
  mm: 1, // 1 mm remains 1 mm
  cm: 10
};

export const convertToMm = (val, type) => {
  const factor = conversionFactors[type];
  if (factor) {
    const mmValue = parseFloat(val) * factor;
    return mmValue;
  } else {
    return val;
  }
};

export function convertValue(value, unitType) {
  const conversionFactors = {
    cm: 0.1,
    mm: 1,
    m: 0.001,
    ft: 0.00328084,
    in: 0.0393701
  };

  const lowercaseUnitType = unitType.toLowerCase();

  if (conversionFactors[lowercaseUnitType]) {
    return value * conversionFactors[lowercaseUnitType];
  } else {
    return customToast('error', MESSAGE.common.invalidUnitType);
  }
}

export const getSimpleId = (fullId) => {
  if (fullId.includes('_')) {
    return fullId.split('_')[0];
  }
  return fullId;
};

export const prepareQueryString = (data) => {
  const queryString = Object.entries(data)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

export const getOrgBillingDetails = (orgList, orgId) => {
  const org = orgList.filter((item) => item._id === orgId)[0];

  if (org?.billing?.status) {
    return org.billing.status;
  }
  return org?.billingStatus;
};
