import React from 'react';
import './initialLoadingScreen.scss';
import konstruLogo from 'assets/images/konstru_logo.png';

function InitialLoadingScreen() {
  return (
    <>
      <div className="loadingscreen-overlay">
        <div className="loading-text">
          <div className="logo">
            <img
              src={konstruLogo}
              className="img-fluid mb-2"
              width={'auto'}
              height={'auto'}
              alt="konstru logo"
            />
          </div>
          <p className="mb-0">Initializing ...</p>
          <p className="mb-0">
            {' '}
            Facing login issues? Please &nbsp;
            <a href="https://konstru.gitbook.io/konstru-manual/konstru-faq/known-issues#login-issues">
              click here
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default InitialLoadingScreen;
