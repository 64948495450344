import React from 'react';
import './networkLoadingLoader.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Networkloadingloader() {
  return (
    <>
      <Modal
        show={true}
        centered
        className="networkloading-modal"
        backdropClassName="networkloadingmodal-backdrop"
      >
        <Modal.Body className="text-center">
          <p className="mb-0 fw-300">
            <FontAwesomeIcon icon={faCog} />
            {/* Fetching latest model data ... */}
            Loading ...
          </p>
          <p className="network-modal-detail">
            {/* This should only take a few moments. <br/> */}
            Taking too long?
          </p>
          <Button
            type="submit"
            variant="orange"
            className="fw-500 font-17 text-white py-3 px-4 mb-2 rounded-0"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon icon={faRotate} /> Refresh Browser
          </Button>
          <span className="d-block">
            Sometimes a refreshing shower does wonder
          </span>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Networkloadingloader;
