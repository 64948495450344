import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  default: false,
  change: null,
  type: false,
  material: false,
  profile: false,
  geometry: false
};

export const viewStyleKeySlice = createSlice({
  name: 'viewStyleKey',
  initialState,
  reducers: {
    Default: (state, action) => {
      state['default'] = action.payload;
    },
    Change: (state, action) => {
      state['change'] = action.payload;
    }
  }
});

export const { Default, Change } = viewStyleKeySlice.actions;

export const viewStyle = viewStyleKeySlice.actions;

export default viewStyleKeySlice.reducer;
