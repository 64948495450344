import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './loader.scss';
import Modal from 'react-bootstrap/Modal';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Networkloadingloader from 'utils/customLayouts/subLayouts/networkLoadingLoader/networkLoadingLoader';

function Loader() {
  const loading = useSelector((store) => store?.loader?.loader);
  const [loaderMessage, setLoaderMesssage] = useState({
    message: 'Sorting through 250000 Entries',
    subline: 'This takes a minute or two...'
  });
  const additionalModelLoading = useSelector(
    (store) => store?.loader?.additionalModelLoader
  );
  const analyticsModelLoading = useSelector(
    (store) => store?.loader?.analyticsLoader
  );
  const analyticsSocketDataLoading = useSelector(
    (store) => store.loader.analyticsSocketDataLoading
  );
  const [refereshBrowser, setRefereshBrowser] = useState(false);

  useEffect(() => {
    if (analyticsSocketDataLoading && analyticsModelLoading) {
      setLoaderMesssage({
        message: 'Sorting through 150000 Entries',
        subline: 'This takes a minute or two...'
      });
    } else if (analyticsModelLoading) {
      setLoaderMesssage({
        message: 'Sorting through 250000 Entries',
        subline: 'This takes a minute or two...'
      });
    }
  }, [analyticsSocketDataLoading, analyticsModelLoading]);

  useEffect(() => {
    let timer;
    window.clearTimeout(timer);
    setRefereshBrowser(false);
    if (loading) {
      timer = setTimeout(() => {
        setRefereshBrowser(true);
      }, 70000);
    } else {
      setRefereshBrowser(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return (
    <>
      {loading &&
        (!refereshBrowser ? (
          <Modal show={true} className="loading-modal" centered>
            {analyticsModelLoading ? (
              <div className="additional-modal-body-loader">
                <div className="busy-content">
                  <p
                    className="text-muted mb-0 fw-300"
                    style={{ fontSize: '1.5rem' }}
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      className="modal-text-setting-icon"
                    />
                    {loaderMessage?.message}
                  </p>
                  <p className="modal-text-lead-1">{loaderMessage?.subline}</p>
                </div>
              </div>
            ) : additionalModelLoading ? (
              <div className="additional-modal-body-loader">
                <div className="busy-content">
                  <p
                    className="text-muted mb-0 fw-300"
                    style={{ fontSize: '1.5rem' }}
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      className="modal-text-setting-icon"
                    />
                    Loading additional models... ...
                  </p>
                  <p className="modal-text-lead-1">
                    We are fetching more models from the server...
                  </p>
                </div>
              </div>
            ) : (
              <Modal.Body>
                <p className="text-muted mb-0 fw-300">
                  <FontAwesomeIcon icon={faCog} /> Loading ...
                </p>
              </Modal.Body>
            )}
          </Modal>
        ) : (
          <Networkloadingloader />
        ))}
    </>
  );
}

export default Loader;
