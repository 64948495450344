let currentUser = null;
let currentModel = null;

export const getModelUnits = (model = null) => {
  if (model && model.settings) return model.settings.units;
  if (currentModel && currentModel.settings) return currentModel.settings.units;
  return {};
};

const getModelFromServer = (modelId) => {
  return getToken().then((token) => {
    return $http
      .get(
        `/api/models/${modelId}?getImages=false&getReports=true`,
        FactoryService.Configurator(token, 'GET')
      )
      .then(FactoryService.errorChecker, FactoryService.errorChecker)
      .then((response) => {
        return this.getDetailedModels([response.data], true).then(
          (populatedModelArr) => {
            insertAndUpdateEditedModel(populatedModelArr[0]);
            // setCurrentModel(populatedModelArr[0]);
          }
        );
      });
  });
};

const getVersionImages = (model) => {
  //model with versions
  const arrFileNames = model.versions.map(
    (v) => `version_${model._id}_${v._id}`
  );
  if (arrFileNames.length) {
    return ResourceFactory.getBatchImageUrls(arrFileNames).then((imageUrls) => {
      return imageUrls.map((url) => url.signed_request);
    });
  } else return $q.when([]);
};

const loadImagesAndVersions = (ids) => {
  return getToken().then((token) => {
    return $http
      .get(
        `/api/models/imagesAndVersionsByModelIds?modelIds=${ids.toString()}`,
        FactoryService.Configurator(token, 'GET')
      )
      .then(FactoryService.errorChecker, FactoryService.errorChecker)
      .then((response) => {
        //populate authors
        let authorIds = [];
        response.data.versions.forEach((m) => {
          m.versions.forEach((v) => {
            authorIds.push(v.Author);
          });
        });
        authorIds = _.uniq(authorIds);
        return UserService.getMultipleUsers(authorIds).then((allUsers) => {
          response.data.versions.forEach((m) => {
            m.versions = m.versions.map((v) => {
              v.Author = _.find(allUsers, { _id: v.Author });
              return v;
            });
          });
          const detailedModels = [];
          //weave in versions and images
          ids.forEach((mid, i) => {
            const model = _.find(self.models, { _id: mid });
            model.imgUrl = response.data.images[i];
            model.versions = _.find(response.data.versions, {
              model: mid
            }).versions;
            detailedModels.push(processModel(model));
          });

          return detailedModels;
        });
      });
  });
};

const processModel = (model) => {
  // Ensure there are some default properties available on the model.
  model.versions = model.versions || [];
  model.reports = model.reports || [];
  model.contributors = model.versions.map((version) => version.Author);
  model.contributors = _.uniqBy(model.contributors, '_id');
  //model.contributors = model.permissions.map(p => p.user);    //TODO: load users in userservice

  // Make an HTTP request for full user information.
  if (model.updatedBy) {
    UserService.getUser(model.updatedBy).then((user) => {
      model.updatedByUser = user;
    });
  } else {
    if (model.versions.length) {
      model.updatedByUser = model.versions[0].Author;
    } else {
      UserService.getUser(model.createdBy).then((user) => {
        model.updatedByUser = user;
      });
    }
  }

  (() => {
    model.versions.sort((a, b) => {
      return new Date(b.Time) - new Date(a.Time);
    });
    model.reports.sort((a, b) => {
      return new Date(b.dateCreated) - new Date(a.dateCreated);
    });
  })();

  // Cache the current user's role within this model.
  const currentUserPermissionObject =
    _.find(model.permissions, { user: currentUser._id }) || {};
  model.currentUserRole = currentUserPermissionObject.type;

  // Process the Reports... add computed properties and request detailed user info.
  model.reports.forEach((report) => {
    report.version = _.find(
      model.versions,
      (version) => version.ID === report.currentVersionID
    );
    report.previousVersion = _.find(
      model.versions,
      (version) => version.ID === report.comparisonVersionID
    );
    report.formattedCreationDate = moment(report.dateCreated).format(
      AppConstants.dateFormat
    );
    report.versionDate = report.version
      ? moment(report.version.Time).format(AppConstants.dateFormat)
      : null;
    report.previousVersionDate = report.previousVersion
      ? moment(report.previousVersion.Time).format(AppConstants.dateFormat)
      : null;

    // Set this to a blank array if no permissions are present.
    report.permissions = report.permissions || [];

    // Cache the current user's role within this report.
    //const currentUserPermissionObject = _.find(report.permissions, {user:currentUser._id}) || {};
    report.currentUserRole = currentUserPermissionObject.type;

    // If you're an admin on the parent model, you automatically get admin access to the reports.
    if (model.currentUserRole === 'ADMIN') {
      report.currentUserRole = model.currentUserRole;
    }

    // Make an HTTP request for full user information.
    UserService.getUser(report.createdBy).then((user) => {
      report.createdByUser = user;
    });
  });

  return model;
};

const removeDeferred = () => {
  deferred = false;
  self.models = null;
  currentUser = null;
};

const insertAndUpdateEditedModel = (updatedModel) => {
  const processedModel = processModel(updatedModel);
  const updateIdx = _.findIndex(self.models, { _id: processedModel._id });
  if (updateIdx > -1) self.models.splice(updateIdx, 1, processedModel);
  if (currentModel && updatedModel._id === currentModel._id)
    setCurrentModel(processedModel);
  return processedModel;
};

const currentTime = new Date().getTime(),
  //const currentTime = new Date("February 28, 2018 11:13:00").getTime(),
  hashTime = 60 * 24 * 60 * 60 * 1000;
