import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationsDetails: []
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    addOrganizationsDetails: (state, action) => {
      if (action?.payload?.length > 0) {
        state.organizationsDetails = [...action.payload];
      }
    },
    clearOrganizationsDetails: (state) => {
      state.organizationsDetails = [];
    }
  }
});

export const { addOrganizationsDetails, clearOrganizationsDetails } =
  organizationsSlice.actions;

export default organizationsSlice.reducer;
